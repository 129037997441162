<template>
  <el-container>
    <el-header>
      <!--     :value="options[0].value" -->
      <el-input
        v-model="queryInfo.departName"
        @blur="getDepartInfo()"
        placeholder="搜索部门名称"
        prefix-icon="el-icon-search"
      />
      <el-button
        type="primary"
        style="margin-left: 5px"
        icon="el-icon-plus"
        @click="showAddDialog('')"
      >
        添加
      </el-button>
    </el-header>

    <el-main>
      <!--操作的下拉框-->
      <el-select
        @change="selectChange"
        clearable
        v-if="selectedInTable.length !== 0"
        v-model="selected"
        :placeholder="'已选择' + selectedInTable.length + '项'"
        style="margin-bottom: 25px;"
      >
        <el-option
          v-for="(item,index) in optionInfo"
          :key="index"
          :value="item.desc"
        >
          <span style="float: left">{{ item.label }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
        </el-option>
      </el-select>

      <el-table
        ref="multipleTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="departInfo"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />

        <el-table-column
          align="center"
          prop="departName"
          label="部门名称"
        />

        <el-table-column
          align="center"
          label="创建时间"
        >
          <template v-slot="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="状态"
        >
          <template v-slot="scope">
            {{ scope.row.enable === 1 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
        >
          <template v-slot="scope">
            <el-button
              type="text"
              size="small"
              v-if="scope.row.parentId>0"
              @click="showAddDialog(scope.row.id)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <el-dialog
      title="部门信息"
      :visible.sync="addTableVisible"
      width="60%"
      @close="resetAddForm"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-row>
          <el-col :span="12" v-if="addForm.parentId !== 0">
        <el-form-item label="上级部门" prop="parentId" label-width="80px">
          <treeselect style=" width: 200px" v-model="addForm.parentId" :options="deptOptions" :normalizer="normalizer" placeholder="选择上级部门" :disable-branch-nodes = "true"/>
        </el-form-item>
          </el-col>
        </el-row>
        <el-input
          v-model="addForm.id"
          type="hidden"
        />
        <el-input
          v-model="addForm.companyId"
          type="hidden"
        />
        <el-input
          v-model="addForm.companyCode"
          type="hidden"
        />
        <el-row>
          <el-col :span="12">
        <el-form-item
          label="部门代码"
          prop="departCode"
        >
          <el-input v-model="addForm.departCode" />
        </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
        <el-form-item
          label="部门名称"
          prop="departName"
        >
          <el-input
            v-model="addForm.departName"
          />
        </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeAddDialog">
          取 消
        </el-button>

        <template v-if="this.formOpt==='add'">
          <el-button
            type="primary"
            @click="addDepart"
          >
            新增
          </el-button>
        </template>
        <template v-if="this.formOpt==='update'">
          <el-button
            type="primary"
            @click="updateDepart"
          >
            修改
          </el-button>
        </template>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import utils from '@/utils/utils'
import depart from '@/api/department'
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect },
  name: 'DepartManage',
  data () {

    return {
      //属性选择器数据对象
      options: [{
        value: '',
        label: ''
      }],
      // 查询用户的参数
      queryInfo: {
        companyId: '',
        departName: '',
        pageNo: 1,
        pageSize: 10
      },
      // 部门
      departInfo: [],
      // 部门树选项
      deptOptions: [],
      // 下拉选择框的数据
      optionInfo: [
        {
          label: '启用',
          desc: 'on'
        },
        {
          label: '禁用',
          desc: 'off'
        },
        {
          label: '删除',
          desc: 'delete'
        }
      ],
      // 下拉框所选择的数据
      selected: '',
      // 被选择的表格中的行数据
      selectedInTable: [],
      // 所有用户的条数
      total: 0,
      // 添加用户的对话框是否显示
      addTableVisible: false,
      formOpt: 'add',
      // 添加用户的表单信息
      addForm: {
        id: '',
        companyId: '',
        companyCode: '',
        departName: '',
        departCode: ''
      },
      // 添加用户表单的验证规则
      addFormRules: {
        departName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: 'blur'
          }
        ],
        departCode: [
          {
            required: true,
            message: '部门代码',
            trigger: 'blur'
          }
        ]
      },
      // 表格信息加载
      loading: true
    }
  },
  created () {
    this.getDepartInfo()
  },
  methods: {
    // 获取用户信息
    getDepartInfo () {
      depart.listDepart(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.departInfo = resp.data
          this.deptOptions = resp.data
          this.loading = false
        } else {
          this.$notify({
            title: 'Tips',
            message: '获取信息失败',
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 表格某一行被选中
    handleSelectionChange (val) {
      this.selectedInTable = val
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.departName,
        children: node.children
      };
    },
    // 功能下拉框被选择
    selectChange (val) {
      // 清空上一次的操作
      this.selected = ''
      // 表格中所选中的用户的id
      const departIds = []
      this.selectedInTable.map(item => {
        departIds.push(item.id)
      })
      if (val === 'on') { // 状态设置为正常
        depart.handlerDepart({ status: 1, ids: departIds }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getDepartInfo()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      } else if (val === 'off') { // 禁用用户
        depart.handlerDepart({ status: 2, ids: departIds }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getDepartInfo()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      } else if (val === 'delete') { // 删除用户
        depart.handlerDepart({ status: 3, ids: departIds }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getDepartInfo()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      }
    },
    // 点击添加按钮
    showAddDialog (id) {
      this.formOpt = 'add'
      if (typeof id !== 'undefined' && id != null && id !== '') {
        depart.getDepartById({ id: id }).then((resp) => {
          this.addForm.departCode = resp.data.departCode
          this.addForm.departName = resp.data.departName
          this.addForm.companyId = resp.data.companyId
          this.addForm.id = resp.data.id
          this.addForm.parentId = resp.data.parentId
          this.addForm.companyCode = resp.data.companyCode
          this.formOpt = 'update'
        })
      }else{

      }
      this.addTableVisible = true
    },
    closeAddDialog () {
      // 清空表格数据
      this.$refs.addForm.resetFields()
      this.addTableVisible = false
    },

    updateDepart () {
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        depart.updateDepart(this.addForm).then((resp) => {
          if (resp.code === 200) {
            this.getDepartInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 新增公司
    addDepart () {
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        depart.addDepart(this.addForm).then((resp) => {
          if (resp.code === 200) {
            this.getDepartInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 表单信息重置
    resetAddForm () {
      // 清空表格数据
      this.$refs.addForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/admin/userManage";
</style>
